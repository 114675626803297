import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';

import SliderComponent from 'components/UI/Slider';

import SliderNowPage from './StartNowPage';
import Pagination from '../Core/Pagination';
import ControlButton from '../Core/ControlButton';

import cmdStyles from '../Core/Slider.module.scss';

const StartNowSlider: React.FC = () => {
  const [autoPlay, setAutoPlay] = useState(true);
  const trRoot = 'components.home.featureStartNow';

  const {
    slideImg1,
    slideImg2,
    slideImg3,
  } = useStaticQuery(
    graphql`
        query {
            slideImg1: file(relativePath: { eq: "home/startnow1.png" }) {
                childImageSharp {
                    fluid(maxWidth: 464) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
            slideImg2: file(relativePath: { eq: "home/startnow2.png" }) {
                childImageSharp {
                    fluid(maxWidth: 464) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
            slideImg3: file(relativePath: { eq: "home/startnow3.png" }) {
                childImageSharp {
                    fluid(maxWidth: 464) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
        }
    `,
  );
  return (
    <section className={cmdStyles.container}>
      <div className={cmdStyles.slider}>
        <SliderComponent
          autoplay={autoPlay}
          renderControls={(
            next,
            previous,
            goTo,
            currentSlide,
            totalSlides,
          ): React.ReactNodeArray => [
            <ControlButton
              key="back-button"
              type="back"
              isArrowsPicMiddle
              onClick={(): void => {
                setAutoPlay(false);
                previous();
              }}
            />,
            <ControlButton
              key="forward-button"
              type="forward"
              mobileVisible
              isArrowsPicMiddle
              onClick={(): void => {
                setAutoPlay(false);
                next();
              }}
            />,
            <Pagination
              key="forward-pagination"
              currentSlide={currentSlide}
              slideCount={totalSlides}
              goToSlide={(slide): void => {
                setAutoPlay(false);
                goTo(slide);
              }}
            />,
          ]}
          useBorders
        >

          <SliderNowPage
            id={1}
            title={`${trRoot}.account.title`}
            description={`${trRoot}.account.description`}
          >
            <GatsbyImage fluid={slideImg1.childImageSharp.fluid} />
          </SliderNowPage>

          <SliderNowPage
            id={2}
            title={`${trRoot}.assign.title`}
            description={`${trRoot}.assign.description`}
          >
            <GatsbyImage fluid={slideImg2.childImageSharp.fluid} />
          </SliderNowPage>

          <SliderNowPage
            id={3}
            title={`${trRoot}.downloadApp.title`}
            description={`${trRoot}.downloadApp.description`}
          >
            <GatsbyImage fluid={slideImg3.childImageSharp.fluid} />
          </SliderNowPage>

        </SliderComponent>
      </div>
    </section>
  );
};

export default StartNowSlider;
