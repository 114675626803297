import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { useIntl } from 'gatsby-plugin-intl';
import { getLocale, Locales } from 'helpers/utils/locale';
import GeneralSettings from 'helpers/constants/settings';

import SliderComponent from 'components/UI/Slider';
import { SwipeDir } from 'components/UI/SwippablePage';
import { isMobileOS } from 'helpers/utils/mobile';

import ControlButton from '../Core/ControlButton';
import Pagination from '../Core/Pagination';
import SliderPage from './SliderPage';
import styles from '../Core/Slider.module.scss';

const Slider: React.FC = () => {
  const [isMobile, setMobile] = useState(false);
  const [autoPlay, setAutoPlay] = useState(true);
  const [sliderHover, setSliderHover] = useState(false);
  const forwardRef = React.useRef<HTMLButtonElement>(null);
  const backRef = React.useRef<HTMLButtonElement>(null);
  const { locale } = useIntl();

  const updateWidth = (): void => {
    const scrW = window.innerWidth;
    const isMobileDevice = isMobileOS() || scrW < GeneralSettings.MOBILE_VIEW.SLIDER_MAIN;
    setMobile(isMobileDevice);
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return (): void => window.removeEventListener('resize', updateWidth);
  }, []);

  const {
    slideImg1,
    slideImg2,
    slideImg2FR,
    slideImg3,
    slideImg3FR,
  } = useStaticQuery(
    graphql`
        query {
            slideImg1: file(relativePath: { eq: "slider/image-1.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
            slideImg2: file(relativePath: { eq: "slider/image-2.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
            slideImg2FR: file(relativePath: { eq: "slider/image-2_fr.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64,
                  }
              }
            }
            slideImg3: file(relativePath: { eq: "slider/image-3.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                        ...GatsbyImageSharpFluidLimitPresentationSize,
                    }
                }
            }
            slideImg3FR: file(relativePath: { eq: "slider/image-3_fr.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64,
                      ...GatsbyImageSharpFluidLimitPresentationSize,
                  }
              }
          }
        }
    `,
  );

  const imgSlide2 = getLocale(locale) === Locales.En ? slideImg2 : slideImg2FR;
  const imgSlide3 = getLocale(locale) === Locales.En ? slideImg3 : slideImg3FR;

  const onSliderEnter = (): void => {
    setSliderHover(true);
  };

  const onSliderLeave = (): void => {
    setSliderHover(false);
  };

  const swipeSlide = (direction: string): void => {
    if (direction === SwipeDir.Left) {
      if (forwardRef.current !== null) {
        forwardRef.current.click();
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (backRef.current !== null) {
        backRef.current.click();
      }
    }
  };

  return (
    <section
      className={styles.container}
      onMouseEnter={onSliderEnter}
      onMouseLeave={onSliderLeave}
    >
      <div className={styles.slider}>
        <SliderComponent
          autoplay={autoPlay}
          renderControls={(
            next,
            previous,
            goTo,
            currentSlide,
            totalSlides,
          ): React.ReactNodeArray => [
            <ControlButton
              key="back-button"
              type="back"
              mobileVisible
              isArrowsPicMiddle
              reference={backRef}
              isHoverEffect={!isMobile}
              isSliderHovered={sliderHover}
              onClick={(): void => {
                setAutoPlay(false);
                previous();
              }}
            />,
            <ControlButton
              key="forward-button"
              type="forward"
              mobileVisible
              isArrowsPicMiddle
              reference={forwardRef}
              isHoverEffect={!isMobile}
              isSliderHovered={sliderHover}
              onClick={(): void => {
                setAutoPlay(false);
                next();
              }}
            />,
            <Pagination
              key="forward-pagination"
              currentSlide={currentSlide}
              slideCount={totalSlides}
              goToSlide={(slide): void => {
                setAutoPlay(false);
                goTo(slide);
              }}
            />,
          ]}
          useBorders
        >

          <SliderPage
            id={1}
            title="components.slider.slide_1.title"
            description="components.slider.slide_1.description"
            onSwipe={swipeSlide}
          >
            <GatsbyImage fluid={slideImg1.childImageSharp.fluid} />
          </SliderPage>

          <SliderPage
            id={2}
            title="components.slider.slide_2.title"
            description="components.slider.slide_2.description"
            onSwipe={swipeSlide}
          >
            <GatsbyImage fluid={imgSlide2.childImageSharp.fluid} />
          </SliderPage>

          <SliderPage
            id={3}
            title="components.slider.slide_3.title"
            description="components.slider.slide_3.description"
            onSwipe={swipeSlide}
          >
            <GatsbyImage fluid={imgSlide3.childImageSharp.fluid} />
          </SliderPage>

        </SliderComponent>
      </div>
    </section>
  );
};

export default Slider;
