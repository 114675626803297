import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { getLocale, Locales } from 'helpers/utils/locale';
import Links from 'helpers/constants/links';

import titleImage from 'images/home/microsoftteams.png';
import syncwithImage from 'images/home/syncwith_rectangle.png';
import Background from './Background';

import styles from './FeatureBanner.module.scss';

interface FeatureBannerProps {
  id: string;
}

const FeatureBanner: React.FC<FeatureBannerProps> = ({
  id,
}) => {
  const { locale } = useIntl();
  const jobsOfferLink = getLocale(locale) === Locales.En
    ? Links.TEAMS.EN : Links.TEAMS.FR;

  return (
    <Container className={styles.container}>
      <Background id={id}>
        <div className={styles.featureBannerContainer}>
          <Row>
            <Col sm="12" md="5" lg="4" xl="4" className={styles.colLeft}>
              <img className={styles.logoImg} src={titleImage} alt="microsoft Teams logo" />
              <img className={styles.syncImg} src={syncwithImage} alt="syncwithImage" />
            </Col>
            <Col sm="12" md="7" lg="8" xl="8" className={styles.colRight}>
              <h2 className={styles.featureTitle}><FormattedMessage id="components.home.featureTeamsIntegration.title" /></h2>
              <p className={styles.featureDescription}>
                <FormattedMessage id="components.home.featureTeamsIntegration.description" />
                {' '}
                <a href={jobsOfferLink} className={styles.featureLink}>
                  <FormattedMessage id="components.home.findOutMore.title" />
                </a>
              </p>
            </Col>
          </Row>
        </div>
      </Background>
    </Container>
  );
};

export default FeatureBanner;
