import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import GatsbyImage from 'gatsby-image';
import { FormattedMessage } from 'react-intl';

import { GatsbyFluidImage } from 'types/gatsby/image';
import Links from 'helpers/constants/links';
import ActionLinkButton from 'components/UI/Buttons/ActionLinkButton';

import { TowColumnsOrderType } from 'types/columnMeasure';
import cmnStyles from 'styles/pages.module.scss';
import featureStyles from '../HomeFeatures.module.scss';
import styles from './Feature.module.scss';

interface FeatureProps {
  id?: string;
  colOrder?: TowColumnsOrderType;
  image: GatsbyFluidImage;
  title: string;
  description: string;
  buttonEnabled: boolean;
  isMirrored?: boolean;
  customImageStyle?: React.CSSProperties;
  customStyle?: React.CSSProperties;
}

const defaultColumns = {
  left: {
    xs: 2,
    sm: 2,
    md: 2,
    lg: 1,
    xl: 1,
  },
  right: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 2,
    xl: 2,
  },
};

const Feature: React.FC<FeatureProps> = ({
  colOrder = defaultColumns,
  image,
  title,
  description,
  buttonEnabled = false,
  isMirrored = false,
  customImageStyle = undefined,
  customStyle = undefined,
}) => {
  const colText = `${cmnStyles.alignSectionVerticallyCenter}`;
  const padImg = isMirrored ? styles.imagePadRt : styles.imagePadLt;
  const custImgStyle = customImageStyle !== undefined ? customImageStyle : {};
  const custStyle = customStyle !== undefined ? customStyle : {};

  const ColImage = (
    <Col
      xs={{ span: 12, order: colOrder.right.xs }}
      sm={{ span: 12, order: colOrder.right.sm }}
      md={{ span: 12, order: colOrder.right.md }}
      lg={{ span: 6, order: colOrder.right.lg }}
      xl={{ span: 6, order: colOrder.right.xl }}
      className={`${styles.image} ${padImg}`}
      style={custImgStyle}
    >
      <GatsbyImage fluid={image.childImageSharp.fluid} />
    </Col>
  );

  const buttonSection = buttonEnabled ? (
    <div className={styles.buttons}>
      <ActionLinkButton
        url={Links.PAGES.SIGNUP}
      >
        <FormattedMessage id="components.header.tryForFree" />
      </ActionLinkButton>
    </div>
  ) : null;
  return (
    <Container>
      <Row className={styles.main} style={custStyle}>
        <Col
          xs={{ span: 12, order: colOrder.left.xs }}
          sm={{ span: 12, order: colOrder.left.sm }}
          md={{ span: 12, order: colOrder.left.md }}
          lg={{ span: 6, order: colOrder.left.lg }}
          xl={{ span: 6, order: colOrder.left.xl }}
          className={colText}
        >
          <h2 className={`${featureStyles.featureTitle} ${styles.header}`}>
            <FormattedMessage id={title} />
          </h2>
          <p className={`${featureStyles.featureDescription} ${styles.textContent}`}>
            <FormattedMessage id={description} />
          </p>
          {buttonSection}
        </Col>
        {ColImage}
      </Row>
    </Container>
  );
};

export default Feature;
