import React, { useState } from 'react';
import styles from './SwipablePage.module.scss';

type SwipablePageProps = {
  id: number;
  onSwipe: (direction: string) => void;
}

export enum SwipeDir {
  Left = 'left',
  Right = 'right'
}

const SwippablePage: React.FC<SwipablePageProps> = ({
  children,
  id,
  onSwipe,
}) => {
  const minDistance = 50;
  const [swiping, setSwiping] = useState(false);
  const [swipeX, setSwipeX] = useState(0);

  const handleTouchStart = (e: React.TouchEvent): void => {
    const touch = e.touches[0];
    setSwipeX(touch.clientX);
    setSwiping(false);
  };

  const handleTouchMove = (e: React.TouchEvent): void => {
    if (e.changedTouches && e.changedTouches.length) {
      setSwiping(true);
    }
  };

  const handleTouchEnd = (e: React.TouchEvent): void => {
    const touch = e.changedTouches[0];
    const absX = Math.abs(touch.clientX - swipeX);
    if (swiping && absX > minDistance) {
      const direction = touch.clientX < swipeX ? SwipeDir.Left : SwipeDir.Right;
      onSwipe(direction);
      setSwiping(false);
    }
  };

  return (
    <div
      className={styles.container}
      key={id}
      onTouchStart={(startEvent: React.TouchEvent): void => handleTouchStart(startEvent)}
      onTouchMove={(moveEvent: React.TouchEvent): void => handleTouchMove(moveEvent)}
      onTouchEnd={(endEvent: React.TouchEvent): void => handleTouchEnd(endEvent)}
    >
      {children}
    </div>
  );
};

export default SwippablePage;
