import React, { useEffect, useState } from 'react';
import GeneralSettings from 'helpers/constants/settings';
import { isMobileOS, isTablet } from 'helpers/utils/mobile';
import playButtonImg from 'images/video/play_button.png';
import styles from './YoutubeVideo.module.scss';

export enum SpecialIntroClasses {
  Mobile4v3 = 'introMobile4v3'
}

interface YoutubeVideoProps {
  url: string;
  title?: string;
  introScreenImg?: string;
  specialIntroClass?: string;
}

const YoutubeVideo: React.FC<YoutubeVideoProps> = ({
  url,
  title = 'Video',
  introScreenImg = '',
  specialIntroClass = '',
}) => {
  const [isIntroVisible, setIntroVIsible] = useState(introScreenImg !== '');
  const [startVideo, setStartVideo] = useState('?autoplay=0');
  const [spIntroCl, setSpIntroCl] = useState('');

  const updateWidth = (): void => {
    const scrW = window.innerWidth;
    const isMobileView = (isMobileOS() && !isTablet())
      || scrW < GeneralSettings.MOBILE_VIEW.EXPLAIN_VIDEO;
    const specialClass = isMobileView ? styles[specialIntroClass] : '';

    setSpIntroCl(specialClass);
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return (): void => window.removeEventListener('resize', updateWidth);
  });

  const onIntroClick = (): void => {
    setStartVideo('?autoplay=1');
    window.setTimeout(() => { setIntroVIsible(false); }, 500);
  };

  const IntroScreen = isIntroVisible ? (
    <div
      aria-label="Play"
      role="button"
      className={`${styles.intro} ${spIntroCl}`}
      style={{ backgroundImage: `url(${introScreenImg})` }}
      tabIndex={0}
      onClick={onIntroClick}
      onKeyPress={onIntroClick}
    >
      <img src={playButtonImg} alt="play" />
    </div>
  ) : null;

  const iFrame = !isIntroVisible ? (
    <iframe
      className={styles.frame}
      title={title}
      src={`${url}${startVideo}`}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  ) : null;

  return (
    <div className={styles.videoContainer}>
      {iFrame}
      {IntroScreen}
    </div>
  );
};

export default YoutubeVideo;
