import React from 'react';
import leftSm from 'images/home/leftsm.svg';
import leftBig from 'images/home/leftbig.svg';
import leftBottom from 'images/home/leftbottom.svg';

import rightNet from 'images/home/rightnet.svg';
import rightTop from 'images/home/righttop.svg';

import bgIntl from './index.module.scss';

const PricingBgIntl: React.FC = () => {
  const bgLeftImages = (
    <>
      <div className={bgIntl.bgLeftSm}>
        <img src={leftSm} alt="leftBallSmall" />
      </div>
      <div className={bgIntl.bgLeftBig}>
        <img src={leftBig} alt="leftBallBig" />
      </div>
      <div className={bgIntl.bgLeftBottom}>
        <img src={leftBottom} alt="leftBallBottom" />
      </div>
    </>
  );
  const bgRightImages = (
    <>
      <div className={bgIntl.rightTop}>
        <img src={rightTop} alt="rightTop" />
      </div>
      <div className={bgIntl.rightNet}>
        <img src={rightNet} alt="rightNet" />
      </div>
    </>
  );

  return (
    <>
      {bgLeftImages}
      {bgRightImages}
    </>
  );
};

export default PricingBgIntl;
