import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Links from 'helpers/constants/links';

import LetsGoButton from './FindOutMoreButton';
import './FindOutMoreButton/Button.scss';
import styles from './BannerCalls.module.scss';

const BannerCalls: React.FC = () => {
  const demoUrl = Links.PAGES.ONOFF_NUMBERS;

  const demoButton = (
    <LetsGoButton title="components.banners.bannerCalls.button" url={demoUrl} />
  );

  return (
    <>
      <Container className={styles.main}>
        <Row>
          <Col sm="8" md="10" lg="10" xl="10">
            <h2 className={styles.title}>
              <FormattedMessage id="components.banners.bannerCalls.title" />
            </h2>
            <p className={styles.description}>
              <FormattedMessage id="components.banners.bannerCalls.description" />
            </p>
          </Col>
          <Col sm="4" md="2" lg="2" xl="2" className={styles.buttons}>
            {demoButton}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BannerCalls;
