import React from 'react';
import numBg from 'images/pricing/french/numbg.svg';

import bgFR from './index.module.scss';

const PricingBgFrench: React.FC = () => {
  const bgLeftImages = (
    <div className={bgFR.bgNumber}>
      <img src={numBg} alt="numberbg" />
    </div>
  );
  const bgRightImages = (
    <>
      <div className={bgFR.bgRightBallSm}> </div>
      <div className={bgFR.bgRightBallBig}> </div>
    </>
  );

  return (
    <>
      {bgLeftImages}
      {bgRightImages}
    </>
  );
};

export default PricingBgFrench;
