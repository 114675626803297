import React from 'react';
import { FormattedMessage } from 'react-intl';

import ActionLinkButton from 'components/UI/Buttons/ActionLinkButton';
import Links from 'helpers/constants/links';
import styles from '../../Core/Slider.module.scss';

interface SliderPageProps {
  id: number;
  title: string;
  description: string;
}

const SliderNowPage: React.FC<SliderPageProps> = ({
  children, id, title, description,
}) => {
  const SignUpButton = (
    <div className={styles.slideButton}>
      <ActionLinkButton
        url={Links.PAGES.SIGNUP}
      >
        <FormattedMessage id="components.slider.buttonJoin" />
      </ActionLinkButton>
    </div>
  );
  return (
    <div className={styles.slideContainer} key={id}>
      <div className={styles.slide}>
        <div className={styles.slideContent}>
          <h2 className={styles.slideTitle}>
            <FormattedMessage id={title} />
          </h2>
          <p className={styles.slideDescription}>
            <FormattedMessage id={description} />
          </p>
          <div className={styles.slideButtons}>
            {SignUpButton}
          </div>
        </div>
        <div className={styles.slideImage}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default SliderNowPage;
