import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useStaticQuery, graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { isMobileOS, isTablet } from 'helpers/utils/mobile';
import { useIntl } from 'react-intl';
import { getLocale, Locales } from 'helpers/utils/locale';

import GeneralSettings from 'helpers/constants/settings';
import YoutubeVideo, { SpecialIntroClasses } from 'components/UI/YoutubeVideo';
import Links from 'helpers/constants/links';
import explainVideoImg from 'images/home/explainvideo.jpg';

import Feature from './Feature';
import FeatureContent from './FeatureContent';
import FeatureStartNow from './FeatureStartNow';
import FeatureBanner from './FeatureBanner';

import styles from './HomeFeatures.module.scss';

const onePhoneColOrder = {
  left: {
    xs: 2,
    sm: 2,
    md: 2,
    lg: 1,
    xl: 1,
  },
  right: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 2,
    xl: 2,
  },
};

const oneAppColOrder = {
  left: {
    xs: 2,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 2,
  },
  right: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    xl: 1,
  },
};

const oneSystemColOrder = {
  left: {
    xs: 2,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 2,
  },
  right: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    xl: 1,
  },
};

const HomeFeatures: React.FC = () => {
  const { locale } = useIntl();
  const [isMobile, setMobile] = useState(false);
  const [isTabletDevice, setTablet] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);
  const videoSource = getLocale(locale) === Locales.En
    ? Links.PAGES.HOME_EXPLAIN_VIDEO : Links.PAGES.HOME_EXPLAIN_VIDEO_FR;

  const explainVideo = (
    <YoutubeVideo
      url={videoSource}
      title="Explain Video"
      introScreenImg={explainVideoImg}
      specialIntroClass={SpecialIntroClasses.Mobile4v3}
    />
  );

  const updateWidth = (): void => {
    const scrW = window.innerWidth;
    setScreenWidth(scrW);
  };

  useEffect(() => {
    updateWidth();
    setTablet(isTablet());
    setMobile(isMobileOS());
    window.addEventListener('resize', updateWidth);
    return (): void => window.removeEventListener('resize', updateWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMobileScreen = (isMobile && !isTabletDevice)
    || screenWidth < GeneralSettings.MOBILE_VIEW.EXPLAIN_VIDEO;
  const applicationOneImageConf = isMobileScreen ? { padding: '20px' } : {};
  const videoBtmPad = isMobile && !isTabletDevice ? '0px' : '50px';
  const onePhonePad = isMobileScreen ? { marginTop: '150px' } : { marginTop: '50px' };

  const PlanIntl = {
    id: 'international',
  };

  const {
    imageFeatureOnePhone,
    imageFeatureApplication,
    imageFeatureApplicationFR,
    imageFeatureSystem,
    imageContentNoSetup,
  } = useStaticQuery(
    graphql`
        query {
          imageFeatureOnePhone: file(relativePath: { eq: "home/onephone.png" }) {
                childImageSharp {
                    fluid(maxWidth: 594) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
          imageFeatureApplication: file(relativePath: { eq: "home/application.png" }) {
              childImageSharp {
                  fluid(maxWidth: 594) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64,
                  }
            }
          }
          imageFeatureApplicationFR: file(relativePath: { eq: "home/application_fr.png" }) {
              childImageSharp {
                  fluid(maxWidth: 594) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64,
                  }
            }
          }
          imageFeatureSystem: file(relativePath: { eq: "home/system.png" }) {
            childImageSharp {
                fluid(maxWidth: 594) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64,
                }
          }
        }
        imageContentNoSetup: file(relativePath: { eq: "home/nosetup.png" }) {
          childImageSharp {
              fluid(maxWidth: 1100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64,
              }
          }
        }    
      }
    `,
  );

  const appImage = getLocale(locale) === Locales.En
    ? imageFeatureApplication : imageFeatureApplicationFR;

  return (
    <Container className={styles.main}>
      <Feature
        id="oneSystem"
        colOrder={oneSystemColOrder}
        title="components.home.featureSystem.title"
        description="components.home.featureSystem.description"
        image={imageFeatureSystem}
        buttonEnabled
        isMirrored
      />
      <FeatureStartNow />
      <FeatureContent
        id="explainVideo"
        offsetBottom={videoBtmPad}
        title="components.home.featureExplainVideo.title"
      >
        {explainVideo}
      </FeatureContent>
      <Feature
        id="onePhone"
        colOrder={onePhoneColOrder}
        title="components.home.featurePhone.title"
        description="components.home.featurePhone.description"
        image={imageFeatureOnePhone}
        customStyle={onePhonePad}
        buttonEnabled={false}
      />
      <Feature
        id="oneApplication"
        colOrder={oneAppColOrder}
        title="components.home.featureApplication.title"
        description="components.home.featureApplication.description"
        image={appImage}
        buttonEnabled
        isMirrored
        customImageStyle={applicationOneImageConf}
      />
      <FeatureContent
        id="noSetup"
        offsetTop="100px"
        title="components.home.featureNoSetup.title"
        description="components.home.featureNoSetup.description"
      >
        <GatsbyImage fluid={imageContentNoSetup.childImageSharp.fluid} />
      </FeatureContent>
      <FeatureBanner
        id={PlanIntl.id}
      />
    </Container>
  );
};

export default HomeFeatures;
