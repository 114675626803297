import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import cmnStyles from 'styles/pages.module.scss';
import featureStyles from '../HomeFeatures.module.scss';
import styles from './FeatureContent.module.scss';

interface FeatureContentProps {
  id?: string;
  offsetTop?: string;
  offsetBottom?: string;
  title: string;
  description?: string;
}

// Feature with title, next row description / image / video
const FeatureContent: React.FC<FeatureContentProps> = ({
  id,
  children,
  offsetTop = '40px',
  offsetBottom = '30px',
  title,
  description = '',
}) => {
  const colText = `${cmnStyles.alignSectionVerticallyCenter}`;

  const customTitle = id === 'explainVideo' ? styles.titleExplainVideo : '';
  const sTitle = `${featureStyles.featureContentTitle} ${customTitle}`;
  const descStyle = `${featureStyles.featureContentDescription} ${styles.description}`;
  const DescriptionSection = description !== '' ? (
    <div className={descStyle}>
      <FormattedMessage id={description} />
    </div>
  ) : null;
  return (
    <Container
      id={id}
      className={styles.wrapper}
      style={{ marginTop: offsetTop, marginBottom: offsetBottom }}
    >
      <Row className={styles.main}>
        <Col sm="12" md="12" lg="12" xl="12" className={colText}>
          <h2 className={sTitle}>
            <FormattedMessage id={title} />
          </h2>
          {DescriptionSection}
        </Col>
      </Row>
      {children}
    </Container>
  );
};

export default FeatureContent;
