import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import cmnStyles from 'styles/pages.module.scss';
import featureStyles from '../../HomeFeatures.module.scss';
import styles from './StartNowColumn.module.scss';

interface FeatureStartNowProps {
  num: number;
  title: string;
  description: string;
  backgroundImage: string;
  offsetY?: number;
}

const StartNowColumn: React.FC<FeatureStartNowProps> = ({
  num,
  title,
  description,
  backgroundImage,
  offsetY = 0,
}) => {
  const colText = `${cmnStyles.alignSectionVerticallyCenter}`;
  const sTitle = `${featureStyles.featureTitle} ${styles.title}`;

  return (
    <Container>
      <Row className={styles.unpad}>
        <Col
          className={styles.image}
          style={{
            backgroundImage: `url(${backgroundImage})`,
            transform: `translateY(${offsetY}px)`,
          }}
        />
      </Row>
      <Row className={styles.main}>
        <Col sm="12" md="6" lg="12" xl="12" className={colText}>
          <h2 className={sTitle}>
            <span>{`${num}. `}</span>
            <FormattedMessage id={title} />
          </h2>
          <p className={styles.description}>
            <FormattedMessage id={description} />
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default StartNowColumn;
