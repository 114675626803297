import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './Button.module.scss';

type linkOptionsType = {
  target: string;
  rel: string;
}

interface ButtonProps {
  title: string;
  url: string;
  onClick?: () => void;
  linkOptions?: linkOptionsType;
}

const FindOutMoreButton: React.FC<ButtonProps> = ({
  title,
  url,
  onClick = (): void => { /* do nothing */ },
  linkOptions = { target: '_blank', rel: 'noopener noreferrer' },
}) => (
  <a
    href={url}
    target={linkOptions.target}
    rel={linkOptions.rel}
    onClick={onClick}
    className={styles.buttonMain}
  >
    <div className={styles.button}>
      <div className={styles.title}>
        <FormattedMessage id={title} />
      </div>
    </div>
  </a>
);

export default FindOutMoreButton;
