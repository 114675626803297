import React from 'react';
import { Container } from 'react-bootstrap';

import PricingBgFrench from './FR';
import PricingBgIntl from './Intl';

import bgFR from './FR/index.module.scss';
import bgIntl from './Intl/index.module.scss';
import styles from './Background.module.scss';

type PricingPlanBgProps = {
  id: string;
}

const PricingPlanBackground: React.FC<PricingPlanBgProps> = ({
  children, id,
}) => {
  let wrapper;
  let bgImages = null;
  if (id === 'french') {
    wrapper = bgFR.main;
    bgImages = (<PricingBgFrench />);
  } else {
    wrapper = bgIntl.main;
    bgImages = (<PricingBgIntl />);
  }
  return (
    <Container className={wrapper}>
      <div className={styles.bgImages}>{bgImages}</div>
      <div>{children}</div>
    </Container>
  );
};

export default PricingPlanBackground;
