import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import StartNowSlider from 'components/Slider/StartNowSlider';
import GeneralSettings from 'helpers/constants/settings';
import { getLocale, Locales } from 'helpers/utils/locale';

import imgStartNow1 from 'images/home/startnow1.png';
import imgStartNow1FR from 'images/home/startnow1_fr.png';
import imgStartNow2 from 'images/home/startnow2.png';
import imgStartNow3 from 'images/home/startnow3.png';
import imgStartNow3FR from 'images/home/startnow3_fr.png';

import cmnStyles from 'styles/pages.module.scss';
import featureStyles from '../HomeFeatures.module.scss';

import StartNowColumn from './StartNowColumn';
import styles from './FeatureStartNow.module.scss';

const ViewPortWidthCollapse = GeneralSettings.MOBILE_VIEW.SLIDER_START_NOW; // px

const FeatureStartNow: React.FC = () => {
  const { locale } = useIntl();
  const [screenWidth, screentWidth] = useState(0);
  const updateWidth = (): void => {
    screentWidth(window.innerWidth);
  };
  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return (): void => window.removeEventListener('resize', updateWidth);
  });

  const trRoot = 'components.home.featureStartNow';
  const colText = `${cmnStyles.alignSectionVerticallyCenter}`;
  const sTitle = `${featureStyles.featureContentTitle} ${styles.title}`;
  const bgImage1 = getLocale(locale) === Locales.En ? imgStartNow1 : imgStartNow1FR;
  const bgImage3 = getLocale(locale) === Locales.En ? imgStartNow3 : imgStartNow3FR;

  const DesktopView = (
    <Container className={styles.startNowWrapper}>
      <Row className={styles.main}>
        <Col sm="12" md="12" lg="12" xl="12" className={colText}>
          <h2 className={sTitle}>
            <FormattedMessage id={`${trRoot}.title`} />
          </h2>
        </Col>
      </Row>
      <Row>
        <Col sm="12" md="12" lg="4" xl="4">
          <StartNowColumn
            num={1}
            title={`${trRoot}.account.title`}
            description={`${trRoot}.account.description`}
            backgroundImage={bgImage1}
          />
        </Col>
        <Col sm="12" md="12" lg="4" xl="4">
          <StartNowColumn
            num={2}
            title={`${trRoot}.assign.title`}
            description={`${trRoot}.assign.description`}
            backgroundImage={imgStartNow2}
          />
        </Col>
        <Col sm="12" md="12" lg="4" xl="4">
          <StartNowColumn
            num={3}
            title={`${trRoot}.downloadApp.title`}
            description={`${trRoot}.downloadApp.description`}
            backgroundImage={bgImage3}
          />
        </Col>
      </Row>
    </Container>
  );

  const MobileView = (
    <Container className={styles.startNowWrapper}>
      <Row className={styles.main}>
        <Col className={colText}>
          <h2 className={styles.mobileTitle}>
            <FormattedMessage id={`${trRoot}.title`} />
          </h2>
        </Col>
      </Row>
      <StartNowSlider />
    </Container>
  );

  const StartNowView = screenWidth > ViewPortWidthCollapse ? DesktopView : MobileView;

  return (
    <>
      {StartNowView}
    </>
  );
};

export default FeatureStartNow;
